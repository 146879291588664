import { ProductItem } from "@/models/stripe/product-item.interface";
import { ApiService } from "vuelando";
import { PaymentIntent } from "@/models/stripe/payment-intent.interface";
import { Card } from "@/models/entities/card.interface";
// import { UserData } from "@/models/entities/billing-address.interface";
import { Receipt } from "@/models/entities/receipt.interfece";
import { Downgrade } from "@/models/entities/downgrade.interface";
import { UpgradePayment } from "@/models/entities/upgrade-payment";
import { UpgradeSubYear } from "@/models/entities/upgrade-sub-year.interface";

const controller = "payments";

class PaymentsService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  createPaymentIntent(priceId: string) {
    return this.post<string, PaymentIntent>(
      `${controller}/createpaymentintent/${priceId}`,
      "void"
    );
  }

  getPaymentMethod() {
    return this.getAll<Card[]>(`${controller}/paymentmethods`);
  }

  getAllCards() {
    return this.getAll<Card[]>(`${controller}/getAllCards`);
  }

  //   retriveCustomer() {
  //     return this.getAll<UserData>(`${controller}/retriveCustomer`);
  //   }

  getAllReceipt() {
    return this.getAll<Receipt[]>(`${controller}/getAllReceipt`);
  }

  //   updateCustomer(userData: UserData) {
  //     return this.post<UserData, void>(`${controller}/updateCustomer`, userData);
  //   }

  createPaymentMethod() {
    return this.getAll<string>(`${controller}/createpaymentmethod`);
  }

  updateCard(card: Card) {
    return this.post<Card, void>(`${controller}/updateCard`, card);
  }

  deleteCard(paymentMethod: string) {
    return this.getAll<void>(
      `${controller}/deleteCard?paymentMethod=${paymentMethod}`
    );
  }

  createSession(payment: UpgradePayment) {
    return this.post<UpgradePayment, string>(
      `${controller}/createSession`,
      payment
    );
  }

  saveSubscription() {
    return this.getAll<void>(`${controller}/savesubscription`);
  }

  updateSubscriptionYear(subscription: UpgradeSubYear) {
    return this.post<UpgradeSubYear, void>(
      `${controller}/upgradesubscriptionyear`,
      subscription
    );
  }
  cancelSubscription() {
    return this.getAll<void>(`${controller}/cancelsubscription`);
  }
  getChangedPlan() {
    return this.getAll<boolean>(`${controller}/getChangedPlan`);
  }
  updatePlan(downgrade: Downgrade) {
    return this.post<Downgrade, void>(`${controller}/updatePlan`, downgrade);
  }

  remainingDaysUp(priceId: string) {
    return this.getAll<string>(`${controller}/remainingdaysup/${priceId}`);
  }
}

export const paymentsService = new PaymentsService();
