
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { required, sameValue } from "@/helpers/rules";
import { userService } from "@/services/api/user.service";
import { SnackbarModule } from "vuelando";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { Card } from "@/models/entities/card.interface";
import { paymentsService } from "@/services/api/payments.service";
import { BillingAddress } from "@/models/entities/billing-address.interface";
import { Receipt } from "@/models/entities/receipt.interfece";
import { loadStripe, Stripe } from "@stripe/stripe-js";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);

const SubscriptionPlanOverview = () =>
  import("./PaymentsComponents/SubscriptionPlanOverview.vue");
// const CardField = () => import("./PaymentsComponents/Payments.vue");
const BillingAddressPage = () =>
  import("./PaymentsComponents/BillingAddressPage.vue");
const ConfirmPlan = () => import("./PaymentsComponents/ConfirmPlan.vue");
const SubscriptionPlan = () =>
  import("./PaymentsComponents/SubscriptionPlan.vue");
const CancelPlan = () => import("./PaymentsComponents/CancelPlan.vue");

type CardIssuer =
  | "Visa"
  | "MasterCard"
  | "DinersClub"
  | "AmEx"
  | "Discover"
  | "JCB"
  | "Maestro"
  | "VisaElectron";

@Component({
  components: {
    "subscription-plan-overview": SubscriptionPlanOverview,
    //  "card-field": CardField,
    "confirm-plan": ConfirmPlan,
    "subscription-plan": SubscriptionPlan,
    "cancel-plan": CancelPlan,
    "billing-address": BillingAddressPage,
  },
})
export default class Payments extends Vue {
  private required = required;

  private showCard = false;
  //   private cancelBillingAddress = false;
  private isFormValid = false;
  private isFormValid2 = false;
  private showSubscriptionPlan = false;
  private showBillingAddress = false;
  private showCancelPlan = false;
  private showConfirmPlan = false;
  private roleId = 0;
  private month = true;
  private duration = null;
  private addTitleSub = "";
  private componentKey = "";
  private previousPage = "";
  private roleIdOld = 0;
  private loaderReceipt = false;
  private downgrade = false;
  private finish = false;
  private previousContext = "";

  stripe: Stripe;

  private currentIssuer: CardIssuer = null;

  private subscriptionPlan: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    montlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    expiration: "",
    freeTrial: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
  };

  private subscriptionExpired = false;

  private cards: Card[] = [
    {
      default: false,
      name: "",
      code: "",
      id: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
      brand: "",
      paymentMethod: "",
    },
  ];

  private newCard: Card = {
    default: false,
    name: "",
    code: "",
    id: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
    brand: "",
    paymentMethod: "",
  };

  private billingAddress: BillingAddress = {
    name: "",
    address: "",
    city: "",
    postalCode: "",
    province: "",
    countryID: 0,
    countryDescr: "",
  };

  private receipts: Receipt[] = [
    {
      date: "",
      cardName: "",
      lastFourDigits: "",
      productName: "",
      amount: "",
      urlDownload: "",
    },
  ];

  private success = false;

  async created(): Promise<void> {
    overlayModule.showOverlay();
    this.stripe = await loadStripe(
      "pk_test_51LLOJiBbR4bAQUnQjJhhKg6k5r42cv5ewzNg5ID2eAxvxEmHUm8Rw7sSmaa2NJRgGhi2hNIPAY7QT5BDPzaOEr5E005SUE9Pdg"
    );
    overlayModule.showOverlay();
    var user = (await userService.GetSubscription()).data;
    authModule.setProfileRoleID(user.roleId);
    let context = this.$route.params.payments;
    if (context == "overview") {
      this.showSubscriptionPlan = true;
      this.showCard = false;
      this.showBillingAddress = false;
      this.showConfirmPlan = false;
      this.showCancelPlan = false;
    }

    await Promise.all([
      this.loadSubscription(),
      // this.loadPaymentMethod(),
      this.getChangedPlan(),
      this.loadBillingAddress(),
      this.loadSubscriptionDuration(),
    ]);

    var parse = this.subscriptionPlan.expiration.split("/");
    this.subscriptionExpired =
      new Date(parse[1] + "/" + parse[0] + "/" + parse[2]).getTime() <
      new Date().getTime();
    this.finish = true;
    this.getAllReceipt();
  }

  private async loadSubscription(): Promise<void> {
    overlayModule.showOverlay();
    this.subscriptionPlan = (await userService.GetSubscription()).data;
  }

  private async getChangedPlan(): Promise<void> {
    overlayModule.showOverlay();
    this.downgrade = (await paymentsService.getChangedPlan()).data;
  }

  private async loadPaymentMethod(): Promise<void> {
    overlayModule.showOverlay();
    this.cards = (await paymentsService.getPaymentMethod()).data;
  }

  private async loadBillingAddress(): Promise<void> {
    overlayModule.showOverlay();
    this.billingAddress = (await userService.GetBillingAddressByID()).data;
  }

  private async getAllReceipt(): Promise<void> {
    this.loaderReceipt = true;
    this.receipts = (await paymentsService.getAllReceipt()).data;
    this.loaderReceipt = false;
  }

  private async loadSubscriptionDuration(): Promise<void> {
    this.duration = (await userService.subscriptionDuration()).data;
    if (this.duration == 3) {
      this.addTitleSub = this.$tc("payments.FreeSubscription");
    }
  }

  private openInvoice(url: string): void {
    window.open(url);
  }

  private async closeBillingAddress() {
    overlayModule.showOverlay();
    this.billingAddress = (await userService.GetBillingAddressByID()).data;
    this.showBillingAddress = false;
    this.changePageRoute("");
  }

  @Watch("$route.params.payments")
  async contextChanged(): Promise<void> {
    let context = this.$route.params.payments;
    if (this.previousContext != context) {
      this.previousContext = context;
      if (context != "home") {
        this.previousContext = context;
        if (context) {
          this.$router.push({
            name: "account",
            params: {
              context: "payments",
              payments: context,
            },
          });
        }
      } else {
        this.changePageRoute("");
      }
    }
    switch (context) {
      case "overview":
        this.showSubscriptionPlan = true;
        this.showCard = false;
        this.showBillingAddress = false;
        this.showConfirmPlan = false;
        this.showCancelPlan = false;
        break;
      case "home":
        this.showSubscriptionPlan = false;
        this.showCard = false;
        this.showBillingAddress = false;
        this.showConfirmPlan = false;
        this.showCancelPlan = false;
        this.loadBillingAddress();
        break;
      case "cancelplan":
        this.showSubscriptionPlan = false;
        this.showCard = false;
        this.showBillingAddress = false;
        this.showConfirmPlan = false;
        this.showCancelPlan = true;
        break;
      case "billingaddress":
        this.showSubscriptionPlan = false;
        this.showCard = false;
        this.showBillingAddress = true;
        this.showConfirmPlan = false;
        this.showCancelPlan = false;
        break;
      case "confirmplan":
        this.showSubscriptionPlan = false;
        this.showCard = false;
        this.showBillingAddress = false;
        this.showConfirmPlan = true;
        this.showCancelPlan = false;
        break;
      default:
        break;
    }
  }

  private async changePageRoute(value: string): Promise<void> {
    if (value != this.previousContext || this.previousContext == "") {
      this.previousContext = value;
      if (value != "" && value != "home") {
        this.$router.push({
          name: "account",
          params: {
            context: "payments",
            payments: value,
          },
        });
      } else {
        this.$router.push({
          name: "account",
          params: {
            context: "payments",
          },
        });
      }
    }
  }

  private showConfirm(roleId: number, toggle: number): void {
    this.roleId = roleId;
    if (toggle == 0) {
      this.month = true;
    } else {
      this.month = false;
    }

    // this.$router.push({
    //   name: "account",
    //   params: {
    //     context: "payments",
    //     payments: "confirmplan",
    //   },
    // });
    this.changePageRoute("confirmplan");
  }

  private async openInserCard(): Promise<void> {
    overlayModule.showOverlay();
    var url = (await paymentsService.createPaymentMethod()).data;
    window.open(url);
  }

  private previousPageManager(roleId: number) {
    this.previousPage = "confirmplan";
    this.roleIdOld = roleId;
  }
}
